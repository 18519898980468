import React from 'react'

const setFavicon = (isGlobalUI) => {
  const head = document.head || document.getElementsByTagName('head')[0]
  const link = document.createElement('link')
  link.rel = 'shortcut icon'
  link.href = isGlobalUI
    ? 'https://dash.freshworks.com/us/assets/images/freshworks-dew.svg'
    : '/api/v2/organisation/-/image?variant=ORIGINAL&entity_type=ORG_FAVICON'
  head.appendChild(link)
}

const addMetaTags = (isGlobalUI) => {
  const meta = document.createElement('meta')
  meta.name = isGlobalUI ? 'description' : 'robots'
  meta.content = isGlobalUI
    ? 'Log in to your Freshworks customer account. Securely sign into Freshdesk, Freshservice, Freshsales, and other Freshworks products. No login? Sign up for a free trial.'
    : 'noindex'
  document.getElementsByTagName('head')[0].appendChild(meta)
}

const addRecaptchaScript = (isGlobalUI) => {
  if (!isGlobalUI) {
    const script = document.createElement('script')
    script.src = 'https://www.recaptcha.net/recaptcha/api.js'
    script.type = 'text/javascript'
    document.head.appendChild(script)
  }
}

const HeadManager = ({ isGlobalUI }) => {
  React.useEffect(() => {
    setFavicon(isGlobalUI)
    addMetaTags(isGlobalUI)
    addRecaptchaScript(isGlobalUI)
  }, [window.location.pathname])

  return null
}

export default HeadManager
