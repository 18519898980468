// import { withAnalytics } from 'react-analytics-collector'

const NON_SESSION_PAGES = {
  LOGIN: 'login',
  LOGIN_MFA: 'login.mfa',
  LOGIN_UPDATE_PASSWORD: 'login.update-password',
  LOGIN_SET_PASSWORD: 'login.set-password',
  LOGIN_FORGOT_PASSWORD: 'login.forgot-password',
  LOGIN_MAGIC_LINK_REQUEST: 'login.magic-link.request',
  LOGIN_RESET_PASSWORD: 'login.reset-password',
  LOGIN_ACTIVATE_USER: 'login.activate-user',
  LOGIN_MAGIC_LINK: 'login.magic-link',
  LOGIN_UNLOCK: 'login.unlock',
  LOGIN_MFA_SETUP: 'login.mfa.setup',
  SESSION_EXISTS: 'session-exists'
}

export const SCREENS = {
  LOGIN_REAUTH: 'login.reauth',
  ...NON_SESSION_PAGES
}

export const getAnalyticsWrapper = (name, Component, props) => {
  return <Component {...props} />
}
